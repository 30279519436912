import { InMemoryCache } from '@apollo/client';

import { publishedResponsesPolicy } from 'common/graphql/fieldPolicies/publishedResponses';
import { readCompanyHelpTextPolicy } from 'common/graphql/fieldPolicies/readCompanyHelpText';
import { searchCompaniesPolicy } from 'common/graphql/fieldPolicies/searchCompanies';
import { searchUserGroupsPolicy } from 'common/graphql/fieldPolicies/searchUserGroups';
import { searchUsersPolicy } from 'common/graphql/fieldPolicies/searchUsers';
import {
  InitialStateDocument,
  InitialStateQuery,
  TypedTypePolicies,
} from 'common/graphql/graphql-hooks';
import fragmentMatcher from './fragment-matcher.json';
import { initialLocalState } from './setupApolloInitialState';
import { readCompanyHelpTextsByModelForAdminPolicy } from 'common/graphql/fieldPolicies/readCompanyHelpTextsByModelForAdmin';

const typePolicies: TypedTypePolicies = {
  Assessment: {
    fields: { responses: { merge: false }, grantees: { merge: false } },
  },
  Attribute: {
    fields: {
      references: { merge: false },
      practiceLevels: { merge: false },
    },
  },
  Coverage: { fields: { sublimits: { merge: false } } },
  InsuranceReview: {
    fields: { policies: { merge: false }, grantees: { merge: false } },
  },
  // License types lack an id field
  License: { keyFields: false, merge: true },
  ResponseDimension: { keyFields: false },
  PracticeLevel: { keyFields: false },
  Response: {
    fields: {
      actionItems: { merge: false },
      dimensions: { merge: false },
      links: { merge: false },
      notes: { merge: false },
    },
  },
  Scenario: {
    fields: {
      attributes: { merge: false },
      businessUnits: { merge: false },
      impacts: { merge: false },
      threatVectors: { merge: false },
      votes: { merge: false },
      oversubscribedImpacts: { merge: false },
    },
  },
  ScenarioCollection: {
    fields: {
      businessUnits: { merge: false },
      controlInitiatives: { merge: false },
      scenarios: { merge: false },
      grantees: { merge: false },
      threatVectors: { merge: false },
    },
  },
  Impact: {
    fields: {
      policyCoverages: { merge: false },
    },
  },
  Company: {
    fields: {
      demographics: { merge: true },
      employees: { merge: false },
      helpTexts: { merge: false },
    },
  },
  CompanyTerminology: { keyFields: false, merge: true },
  Query: {
    fields: {
      practiceQuestions: { merge: false },
      publishedResponses: publishedResponsesPolicy,
      readCompanyHelpText: readCompanyHelpTextPolicy,
      searchCompanies: searchCompaniesPolicy,
      searchUserGroups: searchUserGroupsPolicy,
      searchUsers: searchUsersPolicy,
      readCompanyHelpTextsByModelForAdmin: readCompanyHelpTextsByModelForAdminPolicy,
    },
  },
};

export const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes,
  typePolicies,
});

cache.writeQuery<InitialStateQuery>({
  query: InitialStateDocument,
  data: initialLocalState,
});
