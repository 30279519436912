import { fromJS, Map, OrderedMap } from 'immutable';
import { Dictionary, isNumber, keyBy, mapValues, range } from 'lodash';
import { clamp } from 'assessments/utils/math';

import Logger from 'common/utils/Logger';
import { IResponse } from '../databaseTypes';
import csfToC2m2MapObject from '../models/CSF-C2M2-map';
import csfToCis18MapObject from '../models/CSF-CIS18-map';
import csfToCriMapObject from '../models/CSF-CRI-map';

import {
  filterDomainPractices,
  getLevels,
  DimensionAggregationMethod as ModelDimensionAggregationMethod,
  IModelDimensionSchema,
  IModelDomain,
  IModelLevel,
  IModelObjective,
  IModelPractice,
  IModelState,
  IScoringOptions,
  IModelDimension,
  mapLevelCredit,
} from '../reducers/models/model';
import { precisionRound } from '../utils/math';
import { ActionItem, ModelScope } from 'common/graphql/graphql-hooks';

export type IPartialResponse = Pick<
  IResponse,
  | 'level'
  | 'targetLevel'
  | '_practice_id'
  | 'dimensions'
  | '_id'
  | 'actionItems'
  | 'shouldAddHalfLevelCredit'
>;

export const allResponsesAtLevel = (
  state: IModelState,
  argValue:
    | number
    | ((d: IModelDomain, o: IModelObjective, p: IModelPractice) => number),
  levelKey = 'level'
) => {
  return Map<IResponse>(
    state.data.domains.reduce((accum, d) => {
      d.objectives.map((o) =>
        o.practices.map((p) => {
          const value =
            typeof argValue === 'function' ? argValue(d, o, p) : argValue;
          const dimensions =
            state.dimensionSchema?.dimensions?.map((dimension) => {
              return { key: dimension.key, [levelKey]: value };
            }) ?? [];
          accum[p.id] = dimensions.length
            ? { dimensions }
            : { [levelKey]: value };
          return value;
        })
      );
      return accum;
    }, {})
  );
};

export const getLinkedActionItemsPercentComplete = (
  actionItems: ActionItem[]
): { percentCompleted: number; hasLinked: boolean } => {
  let hasLinked = false;
  let linkedCount = 0,
    doneCount = 0;
  Object.values(actionItems).forEach(({ isDone, isLinked }) => {
    if (isLinked) {
      hasLinked = true;
      linkedCount += 1;
      if (isDone) {
        doneCount += 1;
      }
    }
  });
  const percentCompleted = +(linkedCount ? doneCount / linkedCount : 0).toFixed(
    2
  );
  return {
    percentCompleted: clamp(percentCompleted, 0, 1),
    hasLinked,
  };
};

/**
 * The number of linked action items completed
 * out of the total number of linked action items
 * will affect the current score (percentComplete). Linked action items will
 * contribute to credit between current and target.
 * This interplation number is returned to be added to the current credit.
 * @param currentLevelCredit the current level for this dimension
 * @param targetLevelCredit the target level for this dimension
 * @param percentComplete the percentage that linked action items have been completed
 * @returns extra credit # to be added if linked action items are completed
 */
export const calculateLinkedActionItemsInterpolation = (
  currentLevelCredit: number,
  targetLevelCredit: number,
  percentComplete: number
) => {
  if (!targetLevelCredit) return 0;
  else {
    return (+targetLevelCredit - +currentLevelCredit) * percentComplete;
  }
};

const extractResponseValue = (
  response: Pick<IResponse, 'level' | 'targetLevel'>,
  levelKey: 'level' | 'targetLevel'
) => {
  // For scoring purposes, missing values (either because the key isn't present,
  // is present and set to null or undefined or is -1) are treated as '0'
  const levelValue = response.level;
  const targetValue = response.targetLevel;

  const targetIsUnset = !isNumber(targetValue) || targetValue === -1;
  const currentLevelExceedsTarget =
    isNumber(levelValue) && isNumber(targetValue) && levelValue > targetValue;
  // if target level is the type of quantizing we're doing and either:
  // - there isnt a target
  // - they have exceeded their target
  // then return the current
  return (
    (levelKey === 'level' || targetIsUnset || currentLevelExceedsTarget
      ? levelValue
      : targetValue) ?? -1
  );
};

/**
 * Grabs both the current and target values (weighted)
 * for a multi-dimensional model response using the
 * aggregation method defined at the models dimension schema
 * @param response response to operate on
 * @param disabledDimensions some models have disabled dimensions, this param filters them out
 * @param dimensionSchema the schema for this models dimensions (defines how we aggregate and score)
 * @returns
 */
const getCreditsMultiDimensional = (
  response: QuantizeResponse,
  disabledDimensions: string[],
  dimensionSchema: IModelDimensionSchema
): { current: number; target: number } => {
  let currentResponseCredit = 0;
  let targetResponseCredit = 0;
  const currentDimensionValues: number[] = [];
  const targetDimensionValues: number[] = [];

  const responseDimensions = response.dimensions ?? [];

  const enabledDimensions = responseDimensions.filter(
    (dimension) => !disabledDimensions.includes(dimension.key)
  );

  enabledDimensions.forEach((responseDimension) => {
    const matchedDimension = dimensionSchema.dimensionMap.get(
      responseDimension.key
    );
    const dimensionLevelFactors = dimensionSchema.levelCreditMap.get(
      responseDimension.key
    );

    if (!matchedDimension || !dimensionLevelFactors) {
      currentDimensionValues.push(0);
      targetDimensionValues.push(0);
    } else {
      const currentDimensionValue = extractResponseValue(
        responseDimension,
        'level'
      );
      const targetDimensionValue = extractResponseValue(
        responseDimension,
        'targetLevel'
      );
      const currentDimensionCredit =
        dimensionLevelFactors[currentDimensionValue] ?? 0;
      const targetDimensionCredit =
        dimensionLevelFactors[targetDimensionValue] ?? 0;

      currentDimensionValues.push(currentDimensionCredit);
      targetDimensionValues.push(targetDimensionCredit);
    }
  });

  switch (dimensionSchema.aggregationMethod) {
    case ModelDimensionAggregationMethod.MAX:
      currentResponseCredit = Math.max(...currentDimensionValues);
      targetResponseCredit = Math.max(...targetDimensionValues);
      break;
    case ModelDimensionAggregationMethod.MIN:
      currentResponseCredit = Math.min(...currentDimensionValues);
      targetResponseCredit = Math.min(...targetDimensionValues);
      break;
    case ModelDimensionAggregationMethod.SUM:
      currentResponseCredit += currentDimensionValues.reduce(
        (a, b) => a + b,
        0
      );
      targetResponseCredit = targetDimensionValues.reduce((a, b) => a + b, 0);
      break;
    case ModelDimensionAggregationMethod.AVERAGE:
    default:
      currentResponseCredit =
        currentDimensionValues.reduce((a, b) => a + b, 0) /
        (enabledDimensions.length || 1);
      targetResponseCredit =
        targetDimensionValues.reduce((a, b) => a + b, 0) /
        (enabledDimensions.length || 1);
  }
  return {
    current: currentResponseCredit,
    target: targetResponseCredit,
  };
};

/**
 * Creates a map of dimension keys to dimension objects.
 * @param {Array} practiceDimensions - The array of practice dimension objects.
 * @returns {Map<string, IModelDimension>} - A map with dimension keys as keys and dimension objects as values.
 */
function getDimensionsMap(practiceDimensions): Map<string, IModelDimension> {
  return Map(practiceDimensions.map((dimension) => [dimension.key, dimension]));
}

/**
 * Creates a map of dimension keys to their corresponding level credit dictionary.
 * @param {Array} practiceDimensions - The array of practice dimension objects.
 * @param {Function} mapLevelCredit - The function that maps practice levels to level credits.
 * @returns {Map<string, Dictionary<number>>} - A map with dimension keys as keys and level credit dictionaries as values.
 */
function getLevelCreditMap(
  practiceDimensions
): Map<string, Dictionary<number>> {
  return Map(
    practiceDimensions.map((dimension) => [
      dimension.key,
      mapLevelCredit(dimension.practiceLevels),
    ])
  );
}

/**
 * Creates a map of dimension keys to an object containing the min and max level values.
 * @param {Array} practiceDimensions - The array of practice dimension objects.
 * @returns {Map<string, { min: number; max: number }>} - A map with dimension keys as keys and objects with min and max level values as values.
 */
function getMinMaxLevelMap(
  practiceDimensions
): Map<string, { min: number; max: number }> {
  return Map(
    practiceDimensions.map((dimension) => [
      dimension.key,
      dimension.practiceLevels.reduce(
        (acc, practiceLevel) => {
          if (practiceLevel.value < acc.min) {
            acc.min = practiceLevel.value;
          }
          if (practiceLevel.value > acc.max) {
            acc.max = practiceLevel.value;
          }
          return acc;
        },
        { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER }
      ),
    ])
  );
}

export type QuantizeResponse = IPartialResponse & { weight?: number };

/**
 * quantize converts a response into it's a appropriate
 * value for scoring.
 */
export const quantize = (
  response: QuantizeResponse,
  levelKey: 'level' | 'targetLevel' = 'level',
  levelFactors: Dictionary<number>,
  practiceId: string,
  dimensionSchema?: IModelDimensionSchema,
  disabledDimensions: string[] = [],
  practiceDimensionsMap?: Map<string, IModelDimension[]>,
  hasVariableResponseLevels = false,
  useDropout = false
) => {
  let percentLinkedActionItemsCompleted = 0;
  let linkedShouldAffectScore = false;
  let currentResponseCredit = 0;
  let targetResponseCredit = 0;
  const weight = isNumber(response.weight) ? response.weight : 1;
  const { actionItems, shouldAddHalfLevelCredit } = response;
  /**
   * The following is only applicable if we're
   * scoring level and the response has action items
   * This is where linked action item completion can impact score
   */
  if (!levelKey || (levelKey === 'level' && actionItems)) {
    const { hasLinked, percentCompleted } = getLinkedActionItemsPercentComplete(
      actionItems ?? []
    );
    //models with mil dropout should ignore linked actionItems
    linkedShouldAffectScore = !useDropout && hasLinked;
    percentLinkedActionItemsCompleted = percentCompleted;
  }
  const isMultiDimensionedModel = !!dimensionSchema?.dimensions?.length;
  if (hasVariableResponseLevels) {
    const practiceDimensions = practiceDimensionsMap?.get(practiceId) ?? [];
    const dimensionsMap = getDimensionsMap(practiceDimensions);
    const levelCreditMap = getLevelCreditMap(practiceDimensions);
    const minMaxLevelMap = getMinMaxLevelMap(practiceDimensions);

    const practiceDimensionSchema: IModelDimensionSchema = {
      dimensions: practiceDimensions,
      aggregationMethod:
        dimensionSchema?.aggregationMethod ??
        ModelDimensionAggregationMethod.AVERAGE,
      dimensionMap: dimensionsMap,
      levelCreditMap: levelCreditMap,
      minMaxLevelMap: minMaxLevelMap,
    };

    const { current, target } = getCreditsMultiDimensional(
      response,
      disabledDimensions,
      practiceDimensionSchema
    );
    // for use if we're scoring the assessment in the case of hitting targets;
    if (levelKey === 'targetLevel') currentResponseCredit = target;
    else {
      currentResponseCredit = current;
    }
    targetResponseCredit = target;
  } else if (isMultiDimensionedModel && dimensionSchema) {
    const { current, target } = getCreditsMultiDimensional(
      response,
      disabledDimensions,
      dimensionSchema
    );
    // for use if we're scoring the assessment in the case of hitting targets;
    if (levelKey === 'targetLevel') currentResponseCredit = target;
    else {
      currentResponseCredit = current;
    }
    targetResponseCredit = target;
  } else {
    const responseValue = extractResponseValue(response, levelKey);
    const targetResponseValue = extractResponseValue(response, 'targetLevel');
    targetResponseCredit = levelFactors[targetResponseValue] ?? 0;
    currentResponseCredit = levelFactors[responseValue] ?? 0;
    if (shouldAddHalfLevelCredit && levelKey !== 'targetLevel') {
      currentResponseCredit += halfwayToNextCreditAmount(
        levelFactors,
        responseValue
      );
    }
  }

  if (linkedShouldAffectScore) {
    const linkedActionItemsInterpolation = calculateLinkedActionItemsInterpolation(
      currentResponseCredit,
      targetResponseCredit,
      percentLinkedActionItemsCompleted
    );
    return (currentResponseCredit + linkedActionItemsInterpolation) * weight;
  } else {
    return currentResponseCredit * weight;
  }
};

/**
 * returns the credit value which lies halfway between the current implementation level and the next
 * @param { Dictionary<number> } levelFactors
 * @param { number } responseValue
 *
 * @returns { number } halfwayCreditIncrement
 */
export const halfwayToNextCreditAmount = (
  levelFactors: Dictionary<number>,
  responseValue: number
): number => {
  if (responseValue < 0) return 0;

  const initialResponseCredit = levelFactors[responseValue];

  if (!isFinite(initialResponseCredit)) return 0;

  const ascendingLevelCredits = [
    ...new Set(Object.values(levelFactors)),
  ].sort();

  const currentCreditIndex = ascendingLevelCredits.findIndex(
    (credit) => credit === initialResponseCredit
  );

  if (currentCreditIndex === -1) return 0;

  const maybeNextHighestCredit = ascendingLevelCredits[currentCreditIndex + 1];

  if (!isFinite(maybeNextHighestCredit)) return 0;

  return Math.max((maybeNextHighestCredit - initialResponseCredit) / 2, 0);
};

export const binaryCutoffLevel = (state?: IModelState) => {
  if (!state) {
    Logger.error('Nothing passed into binaryCuttoffLevel function');
  }
  // Code assumes quantizing at bottom half become 0, top half of mils become 1.
  const lvls = state ? getLevels(state) : [];
  return lvls[Math.floor(lvls.length / 2)].value;
};

/** Given a section key that is either a domain prefix like 'RM' or a domain and objective prefix like 'RM.1'
 * return a map of responses where every key in the model has a corresponding key and object in responses.
 * Where the responses map has a matching key, that's the value that is mapped.  Where it doesn't, an empty
 * object is used.  If sectionKey isn't provided, a map of every response is built.
 *
 * The response is also augmented with a mil property if the practice is a mil practice.
 */
export const fullyPopulatedResponses = (
  state: IModelState,
  argResponses: Map<string, IResponse> | IResponse[],
  sectionKey?: string
): Map<string, IResponse & { mil: number }> => {
  const responses = Array.isArray(argResponses)
    ? Map<string, IResponse>(
        argResponses.map((e) => [e._practice_id, e] as [string, IResponse])
      )
    : argResponses;

  const listOfPractices = sectionKey
    ? state.practiceMap.filter((_p, key) => !!key && key.startsWith(sectionKey))
    : state.practiceMap;

  const hasDimensions = state.dimensionSchema?.dimensions.length;
  return listOfPractices.map<IResponse & { mil: number }>((p, k) => {
    const milBit = p?.mil ? { mil: p?.mil } : {};
    const baseLine = {
      level: -1,
      targetLevel: -1,
      ...milBit,
      ...(hasDimensions ? { dimensions: [] as IResponse['dimensions'] } : {}),
    };
    const response = responses.get(k);
    return { ...baseLine, ...response } as IResponse & { mil: number };
  });
};

/** Combines the response value with the weights for the practice to
 * make summing easier
 */
const responseWithWeight = (response?: IPartialResponse, weight?: number) => {
  if (!response) {
    return;
  }
  return {
    weight,
    ...response,
  };
};

/** Gets the maximum value for a practice */
const maximumPracticeScore = (
  practice: IModelPractice,
  dimensionSchema?: IModelDimensionSchema
) => {
  const practiceWeight = practice?.weight ?? 0;
  if (!dimensionSchema?.dimensions.length) return practiceWeight;

  const dimensions = dimensionSchema.dimensions;
  const weightedScores = dimensions.map((dimension) => {
    const creditMap = dimensionSchema.levelCreditMap.get(dimension.key);
    const maxLevel = dimensionSchema.minMaxLevelMap.get(dimension.key)?.max;
    const maxCreditValue = creditMap
      ? Object.values(creditMap).reduce(
          (max, current) => Math.max(max, current),
          -Infinity
        )
      : 0;
    const maxCredit = maxCreditValue
      ? maxCreditValue
      : creditMap && maxLevel
      ? creditMap[maxLevel]
      : 0;
    return maxCredit;
  });
  switch (dimensionSchema.aggregationMethod) {
    case ModelDimensionAggregationMethod.MAX:
      return Math.max(...weightedScores) * practiceWeight;
    case ModelDimensionAggregationMethod.MIN:
      return Math.min(...weightedScores) * practiceWeight;
    case ModelDimensionAggregationMethod.SUM:
      return weightedScores.reduce((a, b) => a + b, 0) * practiceWeight;
    case ModelDimensionAggregationMethod.AVERAGE:
    default:
      return (
        (weightedScores.reduce((a, b) => a + b, 0) * practiceWeight) /
        dimensions.length
      );
  }
};

/** Totals up all the weights and counts all the filtered v. non-filtered for all practices across all objectives. */
const summarizeDomain = (
  objectives: IModelObjective[],
  filter: ScoreFilter | null,
  dimensionSchema?: IModelDimensionSchema,
  practiceDimensionsMap?: Map<string, IModelDimension[]>,
  hasVariableResponseLevels = false
) => {
  return objectives.reduce(
    (oaccum, o) =>
      o.practices.reduce((accum, p) => {
        const passedFilter = !filter || filter(p);
        const practiceDimensions = practiceDimensionsMap?.get(p.id) ?? [];
        const dimensionsMap = getDimensionsMap(practiceDimensions);
        const levelCreditMap = getLevelCreditMap(practiceDimensions);
        const minMaxLevelMap = getMinMaxLevelMap(practiceDimensions);

        const practiceDimensionSchema: IModelDimensionSchema = {
          dimensions: practiceDimensions,
          aggregationMethod:
            dimensionSchema?.aggregationMethod ??
            ModelDimensionAggregationMethod.AVERAGE,
          dimensionMap: dimensionsMap,
          levelCreditMap: levelCreditMap,
          minMaxLevelMap: minMaxLevelMap,
        };
        accum.max += maximumPracticeScore(
          p,
          hasVariableResponseLevels ? practiceDimensionSchema : dimensionSchema
        );
        accum.filteredMax += passedFilter
          ? maximumPracticeScore(
              p,
              hasVariableResponseLevels
                ? practiceDimensionSchema
                : dimensionSchema
            )
          : 0;
        accum.excludedCount += passedFilter ? 0 : 1;
        accum.includedCount += passedFilter ? 1 : 0;
        return accum;
      }, oaccum),
    {
      max: 0,
      filteredMax: 0,
      includedCount: 0,
      excludedCount: 0,
    }
  );
};

/** Totals up all the weights and counts all the filtered v. non-filtered for all practices within an objective. */
const summarizeObjective = (
  practices: IModelPractice[],
  filter: ScoreFilter | null,
  practiceDimensionsMap?: Map<string, IModelDimension[]>,
  hasVariableResponseLevels = false,
  aggregationMethod?: ModelDimensionAggregationMethod
) => {
  return practices.reduce(
    (accum, p) => {
      const practiceDimensions = practiceDimensionsMap?.get(p.id) ?? [];
      const dimensionsMap = getDimensionsMap(practiceDimensions);
      const levelCreditMap = getLevelCreditMap(practiceDimensions);
      const minMaxLevelMap = getMinMaxLevelMap(practiceDimensions);

      const practiceDimensionSchema: IModelDimensionSchema = {
        dimensions: practiceDimensions,
        aggregationMethod:
          aggregationMethod ?? ModelDimensionAggregationMethod.AVERAGE,
        dimensionMap: dimensionsMap,
        levelCreditMap: levelCreditMap,
        minMaxLevelMap: minMaxLevelMap,
      };
      const passedFilter = !filter || filter(p);
      const weight = p.weight;
      accum.max += hasVariableResponseLevels
        ? maximumPracticeScore(p, practiceDimensionSchema)
        : weight || 0;
      accum.filteredMax += hasVariableResponseLevels
        ? maximumPracticeScore(p, practiceDimensionSchema)
        : (passedFilter && weight) || 0;
      accum.excludedCount += passedFilter ? 0 : 1;
      accum.includedCount += passedFilter ? 1 : 0;
      return accum;
    },
    {
      max: 0,
      filteredMax: 0,
      excludedCount: 0,
      includedCount: 0,
    }
  );
};

/** Returns true if the practice level is above the cutoff and false otherwise */
const practiceIsMet = (
  practice: IPartialResponse | undefined,
  cutoffValue: number,
  levelKey: 'level' | 'targetLevel'
) => {
  if (!practice) {
    return false;
  }
  if (levelKey === 'level') {
    const levelValue = practice['level'];
    return (levelValue ?? 0) >= cutoffValue;
  }
  // NOTE if levelKey = 'target' and target level is *explicitly* unset
  // then we treat level as targetLevel
  const targetValueWithFallback =
    !isNumber(practice['targetLevel']) || practice['targetLevel'] === -1
      ? practice['level']
      : practice['targetLevel'];
  return (targetValueWithFallback ?? 0) >= cutoffValue;
};

export type ScoreFilter = (practice: IModelPractice) => boolean;

export interface IScoreValue {
  score: number; // The assessment score (total scaled to bottomOfDomain to topOfDomain)
  filteredScore: number; // The assessment score, just including the filtered values scaled.
  targetScore?: number; // Using target practice values
  max: number; // The maximum possible score
  filteredMax: number; // The max possible for those hitting the filter
  targetMax?: number; // Using tartet practice values
  total: number; // The sum of all the individual values (unscaled)
  includedCount: number; // Count of items that met the filter.
  excludedCount: number; // Count of items that didn't meet the filter.
}

export type ObjectiveSummary = IScoreValue & { fqn: string; title: string };

export type ScoringResult = IScoreValue & {
  domains: {
    [domain: string]: DomainSummary;
  };
};

export type DomainSummary = IScoreValue & {
  objectives: {
    [objective: string]: ObjectiveSummary;
  };
  title: string;
  shortTitle: string;
};

const getDefaultScoreResultValues = (): ScoringResult => {
  return {
    score: 0,
    filteredScore: 0,
    max: 0,
    filteredMax: 0,
    total: 0,
    includedCount: 0,
    excludedCount: 0,
    domains: {},
  };
};

function scoreObjective(
  practicesToScore: IModelPractice[],
  responses: Map<string, IPartialResponse>,
  levelKey: 'level' | 'targetLevel',
  levelCreditMap: {},
  dimensionSchema?: IModelDimensionSchema,
  practiceDimensionsMap?: Map<string, IModelDimension[]>,
  hasVariableResponseLevels = false,
  useDropout = false
) {
  return practicesToScore.reduce<Pick<ObjectiveSummary, 'total'>>(
    (practiceAccum, practice) => {
      const practiceWeight = practice.weight;
      if (practiceWeight === null) {
        return practiceAccum;
      }
      const response = responseWithWeight(
        responses.get(practice.id),
        practiceWeight
      );
      if (!response) {
        return practiceAccum;
      }

      practiceAccum.total += quantize(
        response,
        levelKey,
        levelCreditMap,
        practice.id,
        dimensionSchema,
        practice.disabledDimensions,
        practiceDimensionsMap,
        hasVariableResponseLevels,
        useDropout
      );
      return practiceAccum;
    },
    {
      total: 0,
    }
  );
}

function scoreAndSummarizeObjective(
  objective: IModelObjective,
  practicesToScore: IModelPractice[],
  filterFn: ((practice: IModelPractice) => boolean) | null,
  responses: Map<string, IPartialResponse>,
  levelKey: 'level' | 'targetLevel',
  levelCreditMap: {},
  dimensionSchema?: IModelDimensionSchema,
  practiceDimensionsMap?: Map<string, IModelDimension[]>,
  hasVariableResponseLevels = false,
  useDropout = false
) {
  return {
    ...summarizeObjective(
      practicesToScore,
      filterFn,
      practiceDimensionsMap,
      hasVariableResponseLevels,
      dimensionSchema?.aggregationMethod
    ),
    ...scoreObjective(
      practicesToScore,
      responses,
      levelKey,
      levelCreditMap,
      dimensionSchema,
      practiceDimensionsMap,
      hasVariableResponseLevels,
      useDropout
    ),
    score: 0,
    filteredScore: 0,
    fqn: objective.fqn,
    title: objective.title,
  };
}

function calcScoreAndFilteredScore(
  objectiveSummary: Pick<ObjectiveSummary, 'total' | 'max' | 'filteredMax'>,
  useScaling: boolean,
  bottomOfDomain: number,
  topOfDomain: number
) {
  // Note that we're reusing the bottomOfDomain/topOfDomain concept at the objective level
  // This was not an explicit choice but instead skipping having to make a decision
  // Since these values are always 0/100, score/filteredScore are essentially percentages
  return useScaling
    ? {
        score:
          bottomOfDomain +
          (objectiveSummary.total / objectiveSummary.max) * topOfDomain,

        filteredScore:
          bottomOfDomain +
          (objectiveSummary.filteredMax
            ? objectiveSummary.total / objectiveSummary.filteredMax
            : 1) *
            topOfDomain,
      }
    : {
        score: objectiveSummary.total,
        filteredScore: objectiveSummary.filteredMax
          ? objectiveSummary.total
          : 1,
      };
}

function addScoreAndFilteredScoreToObjectiveSummary(
  objectiveSummary: ObjectiveSummary,
  useScaling: boolean,
  bottomOfDomain: number,
  topOfDomain: number
) {
  // Note that we're reusing the bottomOfDomain/topOfDomain concept at the objective level
  // This was not an explicit choice but instead skipping having to make a decision
  // Since these values are always 0/100, score/filteredScore are essentially percentages
  const scoreAndFilteredScore = calcScoreAndFilteredScore(
    objectiveSummary,
    useScaling,
    bottomOfDomain,
    topOfDomain
  );

  return {
    ...objectiveSummary,
    ...scoreAndFilteredScore,
  };
}

function addScoreAndFilteredScoreToDomainSummary(
  domainSummary: DomainSummary,
  useScaling: boolean,
  bottomOfDomain: number,
  topOfDomain: number
) {
  const scoreAndFilteredScore = calcScoreAndFilteredScore(
    domainSummary,
    useScaling,
    bottomOfDomain,
    topOfDomain
  );

  return {
    ...domainSummary,
    ...scoreAndFilteredScore,
  };
}

function everyPracticeIsMet(
  practices: IModelPractice[],
  responses: Map<string, IPartialResponse>,
  binaryCutoff: number,
  levelKey: 'level' | 'targetLevel'
) {
  return practices.every((p) => {
    const practiceWeight = p.weight;
    const response =
      practiceWeight != null
        ? responseWithWeight(responses.get(p.id), practiceWeight)
        : undefined;
    return practiceIsMet(response, binaryCutoff, levelKey);
  });
}

function score2(
  scoringOptions: IScoringOptions,
  levels: IModelLevel[],
  milRange: { min: number; max: number },
  domains: IModelDomain[],
  filterFn: ((practice: IModelPractice) => boolean) | null,
  responses: Map<string, IPartialResponse>,
  levelKey: 'level' | 'targetLevel',
  practiceDimensionsMap?: Map<string, IModelDimension[]>,
  dimensionSchema?: IModelDimensionSchema,
  hasVariableResponseLevels = false
) {
  const initialResult: ScoringResult = getDefaultScoreResultValues();

  const {
    offset: effectiveOffset,
    useScaling,
    useDropout,
    bottomOfDomain,
    topOfDomain,
    bottomOfScale,
    topOfScale,
  } = scoringOptions;
  const offset = effectiveOffset ?? 0;

  const hasDimensions = !!dimensionSchema?.dimensions.length;
  const binaryCutoff = hasDimensions
    ? 0
    : levels[Math.floor(levels.length / 2)].value;
  const levelCreditMap = mapValues(keyBy(levels, 'value'), 'credit');
  const mils = milRange.max < 0 ? [] : range(milRange.min, milRange.max + 1);

  // Now that we've defined all the pieces, run the loop pulling in
  // all of the data.
  const result = domains.reduce<ScoringResult>((dAccum, d) => {
    const domainSummary = d.objectives.reduce<DomainSummary>(
      (objectiveAccum, o) => {
        if (mils.length === 0 || !useDropout) {
          const practicesToScore = filterFn
            ? o.practices.filter(filterFn)
            : o.practices;

          const objectiveSummary = scoreAndSummarizeObjective(
            o,
            practicesToScore,
            filterFn,
            responses,
            levelKey,
            levelCreditMap,
            dimensionSchema,
            practiceDimensionsMap,
            hasVariableResponseLevels,
            useDropout
          );
          objectiveAccum.objectives[
            o.id
          ] = addScoreAndFilteredScoreToObjectiveSummary(
            objectiveSummary,
            useScaling,
            bottomOfDomain,
            topOfDomain
          );
          objectiveAccum.total += objectiveSummary.total;
        } else {
          // we have mils -- score each mil with dropout
          // Dropout happens because of the "every" below.
          //
          // We are iterating the MIls in order to that as soon as
          // a mil is found that doesn't have every practice met,
          // we break the loop.
          //
          // Some sort of practie dependency graph would be a more
          // generic implementation (for models with dependencies
          // that are not mil based)
          mils.every((mil) => {
            const practices = o.practices.filter((p) => p.mil === mil);
            const practicesToScore = filterFn
              ? practices.filter(filterFn)
              : practices;

            const objectiveSummary = scoreAndSummarizeObjective(
              o,
              practicesToScore,
              filterFn,
              responses,
              levelKey,
              levelCreditMap,
              dimensionSchema,
              practiceDimensionsMap,
              hasVariableResponseLevels,
              useDropout
            );
            objectiveAccum.total += objectiveSummary.total;

            //is this the first mil?
            let currObjectiveSummary = objectiveAccum.objectives[o.id];
            if (currObjectiveSummary)
              currObjectiveSummary = sumObjectiveSummaries(
                objectiveSummary,
                currObjectiveSummary
              );

            //why only addScoreAndFilteredScoreToObjectiveSummary on first objectiveSummary before we have currObjectiveSummary?
            objectiveAccum.objectives[o.id] =
              currObjectiveSummary ??
              addScoreAndFilteredScoreToObjectiveSummary(
                objectiveSummary,
                useScaling,
                bottomOfDomain,
                topOfDomain
              );

            // If every practice isn't met return false, which breaks the every loop.
            return everyPracticeIsMet(
              practicesToScore,
              responses,
              binaryCutoff,
              levelKey
            );
          });
        }
        return objectiveAccum;
      },
      {
        ...summarizeDomain(
          d.objectives,
          filterFn,
          dimensionSchema,
          practiceDimensionsMap,
          hasVariableResponseLevels
        ),
        score: 0,
        filteredScore: 0,
        total: 0,
        objectives: {},
        title: d.title,
        shortTitle: d.shortTitle,
      }
    );
    dAccum.total += domainSummary.total;
    dAccum.max += domainSummary.max;
    dAccum.filteredMax += domainSummary.filteredMax;
    dAccum.excludedCount += domainSummary.excludedCount;
    dAccum.includedCount += domainSummary.includedCount;
    dAccum.domains[d.name] = addScoreAndFilteredScoreToDomainSummary(
      domainSummary,
      useScaling,
      bottomOfDomain,
      topOfDomain
    );
    return dAccum;
  }, initialResult);
  //NOTE: we add the offset regardless of scaling (but in pratice the only model with an offset does not use scaling)
  result.score =
    (useScaling
      ? bottomOfScale + (result.total / result.max) * topOfScale
      : result.total) + offset;

  result.filteredScore =
    (useScaling
      ? bottomOfScale +
        (result.filteredMax ? result.total / result.filteredMax : 1) *
          topOfScale
      : result.total) + offset;

  // Note that domain and objective level scoring values are not rounded?
  // There is no explicit reason for this, just how the original person chose to do this
  let legacyResult = Object.keys(result).reduce((newObj, key) => {
    if (key !== 'domains') {
      newObj[key] = precisionRound(result[key], 3);
    }
    return newObj;
  }, result);
  
  return legacyResult;
}

/**
 * Builds a score for each domain in the model, each objective, and the model as a whole.
 *
 * @param {IModelState} state
 * The model data.
 *
 * @param {Map<string, IPartialResponse>} responses
 * The collection of assessment responses
 *
 * @param modelScope
 * If assessment has a MIL level, use corresponding milDomains array
 *
 * @param {string} levelKey
 * Which key to use.  "level" or "targetLevel"
 *
 * @param {ScoreFilter} filterFn
 * Function for filtering the responses to a subset.
 *
 * @param {string} modelAsOfDate
 * Use the model as it were on modelAsOfDate.
 *
 * @return {ScoringResult}
 * The top level and by domain score and by objective score
 */
export const score = (
  state: Pick<
    IModelState,
    | 'modelUuid'
    | 'scoring'
    | 'data'
    | 'milRange'
    | 'factories'
    | 'milDomains'
    | 'practiceMap'
    | 'practiceDimensionsMap'
    | 'dimensionSchema'
  >,
  responses: Map<string, IPartialResponse> | null = null,
  modelScope: ModelScope | null = null,
  levelKey: 'level' | 'targetLevel' = 'level',
  filterFn: ScoreFilter | null = null,
  modelAsOfDate: string | null = null
): ScoringResult => {
  // For each domain, the score is the quantized value of the selected
  // level key ('level' or 'targetLevel') * the weight for each practice
  // HOWEVER... if you haven't gotten credit for all lower MIL values in an
  // objective, you can't get the points for the upper level ones.

  if (!responses) {
    return getDefaultScoreResultValues();
  }
  const hasVariableResponseLevels = state.data.domains.some((domain) =>
    domain.objectives.some((objective) =>
      objective.practices.some(
        (practice) => practice?.dimensions && practice.dimensions.length > 0
      )
    )
  );
  const milRange = state.milRange;
  const levels = state.data.practiceLevels;
  const milValue = modelScope?.startsWith('MIL')
    ? parseInt(modelScope.slice(-1))
    : null;

  const domains0 = milValue ? state.milDomains[milValue] : state.data.domains;
  const scoringOptions = state.scoring;
  const dimensionSchema = state.dimensionSchema;
  const practiceDimensionsMap = state.practiceDimensionsMap;
  const domains1 = modelAsOfDate
    ? filterDomainPractices({
        domains: fromJS(domains0),
        filterFn: (p) => {
          if (modelAsOfDate && !p.get('since')) {
            return true;
          }
          //modelAsOfDate is a string
          return new Date(modelAsOfDate) >= new Date(p.get('since'));
        },
      })
    : domains0;

  const result = score2(
    scoringOptions,
    levels,
    milRange,
    domains1,
    filterFn,
    responses,
    levelKey,
    practiceDimensionsMap,
    dimensionSchema,
    hasVariableResponseLevels
  );

  return result;
};

const sumObjectiveSummaries = (
  summary1: ObjectiveSummary,
  summary2: ObjectiveSummary
) => ({
  ...summary1, //need objective name, etc which doesn't sum
  score: summary1.score + summary2.score,
  filteredScore: summary1.filteredScore + summary2.filteredScore,
  max: summary1.max + summary2.max,
  filteredMax: summary1.filteredMax + summary2.filteredMax,
  total: summary1.total + summary2.total,
  includedCount: summary1.includedCount + summary2.includedCount,
  excludedCount: summary1.excludedCount + summary2.excludedCount,
});

// Calculates the CSF value of a C2M2 response.  We're multiplying the
// credit a level on the FILIPINI scale gives you (from none to full)
// by the load - which is the amount a particular practice contributes to
// a given subcategory.
const csfValue = (
  response: IResponse | null | undefined,
  levelKey: 'level' | 'targetLevel',
  cutoff: number,
  load: number
) => {
  return cutoff === -1
    ? getPartialCredit(response?.[levelKey] ?? 0) * load
    : cutoff < (response?.[levelKey] ?? 0)
    ? load
    : 0;
};

export interface IScoredCategory {
  id: string;
  title: string;
  category: string;
  complete: number;
  targetComplete: number;
  total: number;
}

export interface IScoredFunction {
  id: string;
  title: string;
  function: string;
  complete: number;
  targetComplete: number;
  total: number;
  categories: { [categoryKey: string]: IScoredCategory };
}

/**
 * this function could use polish
 * state arg is unused
 * various things like cutoff are magic numbers
 * why does it need the csf>>c2m2 mapping file
 * comments are wrong e.g. first two comment inside function
 */

/* Builds a score for each category in the CSF model and the model as a whole
 * given a C2M2 as input
 */

export const csfScore = (
  state: IModelState,
  argResponses: Map<string, IResponse> | null = null,
  levelKey: 'level' | 'targetLevel' = 'level'
) => {
  // Grab the csf model off of the state to get the list of functions/domains.
  const responses = argResponses ? argResponses : OrderedMap<IResponse>({});
  const cutoff = -1;

  // Create a copy of the csf->c2m2 map and drill the scores that don't
  // have responses hitting the cutoff.
  const result = Object.keys(csfToC2m2MapObject).reduce<{
    [functionId: string]: IScoredFunction;
  }>((accum, subcatkey) => {
    const [, csfFunction, category, subcat] = subcatkey.split('.');
    // subcatkey != 8 means we have function or category metadata rows, so skip over
    if (!subcat) {
      return accum;
    }

    const functionKey = '.' + csfFunction;
    const categoryKey = functionKey + '.' + category;

    accum[functionKey] = accum[functionKey] || {
      id: functionKey,
      function: csfFunction,
      title: csfToC2m2MapObject[functionKey],
      total: 0,
      complete: 0,
      targetComplete: 0,
      categories: {},
    };

    accum[functionKey].categories[categoryKey] = accum[functionKey].categories[
      categoryKey
    ] || {
      id: categoryKey,
      category: csfFunction + '.' + category,
      title: csfToC2m2MapObject[categoryKey],
      total: 0,
      complete: 0,
      targetComplete: 0,
    };

    const subcatItem: { [FQN: string]: number } = csfToC2m2MapObject[subcatkey];
    const complete = Object.keys(subcatItem).reduce((compAccum, practiceId) => {
      return (
        compAccum +
        csfValue(
          responses.get(practiceId),
          levelKey,
          cutoff,
          subcatItem[practiceId]
        )
      );
    }, 0);

    const targetComplete = Object.keys(subcatItem).reduce(
      (compAccum, practiceId) => {
        return (
          compAccum +
          csfValue(
            responses.get(practiceId),
            'targetLevel',
            cutoff,
            subcatItem[practiceId]
          )
        );
      },
      0
    );

    // Remember that the values in the map are 100 based, not 1 based
    // to keep rounding from wrecking us.
    accum[functionKey].total += 100;
    accum[functionKey].complete += complete;
    accum[functionKey].targetComplete += targetComplete;
    accum[functionKey].categories[categoryKey].total += 100;
    accum[functionKey].categories[categoryKey].complete += complete;
    accum[functionKey].categories[categoryKey].targetComplete += targetComplete;

    return accum;
  }, {});

  // This are easier for the graphs if these values are arrays instead
  // of maps/objects, so we'll convert them.
  return Object.values(result).map((func) => ({
    ...func,
    categories: Object.values(func.categories),
  }));
};

// Returns the csf levels mapped from c2m2.
export const csfLevelsFromC2m2 = (
  argResponses: Map<string, IResponse> | null = null
): Map<string, IResponse> => {
  // Grab the csf model off of the state to get the list of functions/domains.

  const responses = argResponses ? argResponses : OrderedMap<IResponse>({});

  // Create a copy of the csf->c2m2 map and drill the scores that don't
  // have responses hitting the cutoff.
  const mapping = {};

  const csfToC2m2Map = Map<string, any>(Object.entries(csfToC2m2MapObject));

  csfToC2m2Map.forEach((value, csfKey) => {
    if (value instanceof Object) {
      mapping[csfKey ?? 'undefined'] = {};
      ['level', 'targetLevel'].forEach((levelType) => {
        const weightedSum = Object.keys(value).reduce((accum, c2m2Key) => {
          const response = responses.get(c2m2Key);
          if (!response) {
            return accum;
          }
          mapping[csfKey ?? 'undefined']._practice_id = csfKey;
          const weight = value[c2m2Key];
          const credit = getPartialCredit(response[levelType]);
          return accum + weight * credit * 0.01;
        }, 0);
        mapping[csfKey ?? 'undefined'][levelType] = getPercentToFILIPINI(
          weightedSum
        );
      });
    }
  });
  return Map(Object.entries(mapping));
};

export const csfLevelsFromCis18 = (
  argResponses: Map<string, IResponse> | null = null
): Map<string, IResponse> => {
  // Grab the csf model off of the state to get the list of functions/domains.

  const responses = argResponses ? argResponses : OrderedMap<IResponse>({});

  // Create a copy of the csf->c2m2 map and drill the scores that don't
  // have responses hitting the cutoff.
  const mapping = {};

  const csfToCis18Map = Map<string, any>(Object.entries(csfToCis18MapObject));

  csfToCis18Map.forEach((value, csfKey) => {
    if (value instanceof Object) {
      mapping[csfKey ?? 'undefined'] = {};
      ['level', 'targetLevel'].forEach((levelType) => {
        const weightedSum = Object.keys(value).reduce((accum, cis18Key) => {
          const response = responses.get(cis18Key);
          if (!response) {
            return accum;
          }
          const numOfDimensions = response?.dimensions?.length ?? 1;
          const level =
            response?.dimensions?.reduce((acc, dimension: any) => {
              if (!dimension) {
                return acc;
              }
              return acc + dimension.level;
            }, 0) ?? 0;
          const weightedLevel = level / numOfDimensions;
          const weight = value[cis18Key];
          const credit = getCis18PartialCredit(weightedLevel);
          return accum + weight * credit * 0.01;
        }, 0);
        mapping[csfKey ?? 'undefined'][levelType] = getPercentToFILIPINI(
          weightedSum
        );
      });
    }
  });
  return Map(Object.entries(mapping));
};

export const csfLevelsFromCri = (
  argResponses: Map<string, IResponse> | null = null
): Map<string, IResponse> => {
  // Grab the csf model off of the state to get the list of functions/domains.

  const responses = argResponses ? argResponses : OrderedMap<IResponse>({});

  // Create a copy of the csf->c2m2 map and drill the scores that don't
  // have responses hitting the cutoff.
  const mapping = {};

  const csfToCriMap = Map<string, any>(Object.entries(csfToCriMapObject));

  csfToCriMap.forEach((value, csfKey) => {
    if (value instanceof Object) {
      mapping[csfKey ?? 'undefined'] = {};
      ['level', 'targetLevel'].forEach((levelType) => {
        const weightedSum = Object.keys(value).reduce((accum, criKey) => {
          const response = responses.get(criKey);
          if (!response) {
            return accum;
          }
          mapping[csfKey ?? 'undefined']._practice_id = csfKey;
          const weight = value[criKey];
          const credit = getCriPartialCredit(response[levelType]);
          return accum + weight * credit * 0.01;
        }, 0);
        mapping[csfKey ?? 'undefined'][levelType] = getPercentToFILIPINI(
          weightedSum
        );
      });
    }
  });
  return Map(Object.entries(mapping));
};

//Note: presumably this could vary with model
const getPartialCredit = (level: number) => {
  switch (true) {
    case level === 3:
      return 1;
    case level === 2:
      return 0.8;
    case level === 1:
      return 0.2;
    default:
      return 0;
  }
};

const getCis18PartialCredit = (level: number) => {
  switch (true) {
    case level === 4:
      return 1;
    case level === 3:
      return 0.8;
    case level === 2:
      return 0.5;
    case level === 1:
      return 0.2;
    default:
      return 0;
  }
};

const getCriPartialCredit = (level: number) => {
  switch (true) {
    case level === 7:
      return 1;
    case level === 6:
      return 1;
    case level === 5:
      return 1;
    case level === 4:
      return 0;
    case level === 3:
      return 0;
    case level === 2:
      return 0;
    case level === 1:
      return 0;
    default:
      return 0;
  }
};

//Note: presumably this could vary with model
const getPercentToFILIPINI = (level: number) => {
  switch (true) {
    case level < 0.1:
      return 0;
    case level < 0.6:
      return 1;
    case level < 0.9:
      return 2;
    default:
      return 3;
  }
};

export const getDisplayScore = (
  rawScore: number,
  decimalPlaces: number
): string =>
  decimalPlaces === 0
    ? Math.round(rawScore).toString()
    : rawScore.toFixed(decimalPlaces);
