import { Map as ImmutableMap } from 'immutable';
import { AnyAction, Reducer } from 'redux';
import { QueryResult } from '@apollo/client';

import { IModelState } from './model';
import {
  GetActiveModelQuery,
  GetLicensedModelsQuery,
  LegacyModelIdEnum,
  ModelFieldsFragment,
} from 'common/graphql/graphql-hooks';
import { getLegacyModelConfigs } from './legacyModelConfig';
import { modelStateFactory } from './modelStateFactory';
import { isEmpty } from 'lodash';

// These are the GraphQL Operations this reducer will listen to for incoming models
const QueryOperationNames = [`GetLicensedModels`, `GetActiveModel`]
type QueryResultType = QueryResult<GetLicensedModelsQuery> | QueryResult<GetActiveModelQuery>

const legacyModelConfig = getLegacyModelConfigs();

export type ILicensedModelsState = ImmutableMap<string, IModelState>;
const initialState = ImmutableMap({}) as ILicensedModelsState;
export const modelReducer: Reducer<ILicensedModelsState, AnyAction> = ( 
  state = initialState,
  action
) =>
  shouldUpdateModels(action)
    ? mergeIncomingModelsToState(action, state)
    : state;

const shouldUpdateModels = (action: AnyAction): boolean =>
  action.type === 'APOLLO_QUERY_RESULT' &&
  QueryOperationNames.includes(action.operationName)

const extractModelsFromResult = (result: QueryResultType): ModelFieldsFragment[] => {
  if (!result.data) return []
  if (`getLicensedModels` in result.data) return result.data.getLicensedModels
  if (result.data.me.activeAssessment?.model) return [result.data.me.activeAssessment.model]

  return []
}

const mergeIncomingModelsToState = (
  action: AnyAction,
  currentState: ILicensedModelsState
): ILicensedModelsState => {
  const result = action.result as QueryResultType;
  const incomingModels = extractModelsFromResult(result)

  if (!incomingModels) return currentState;

  const newModels = incomingModels.reduce<Record<string, IModelState>>(
    (acc, model) => {
      const key = model.uuid as LegacyModelIdEnum;

      if (currentState.has(key)) return acc;

      const legacyConfig = legacyModelConfig.get(key);
      const modelState = modelStateFactory(model, legacyConfig);
      acc[model.uuid] = modelState;

      return acc;
    },
    {}
  );

  if (isEmpty(newModels)) {
    return currentState;
  }

  return currentState.merge(newModels);
};
