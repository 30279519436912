// Constants scoped to the assessments module
export const DOMAINANCHOR_CLASSNAME = 'DomainAnchorClass';
export const DOMAINHEADER_CLASSNAME = 'DomainHeader';

export const DOMAINBAR_PADDING = 16;
export const DOMAINBAR_HEIGHT = 52;

export const FOOTER_HEIGHT = 60;
export const ASSESSMENT_LIST_WIDTH = 300;

export const ASSESSMENT_ID_FIELD = '_assessment';
export const PRACTICE_ID_FIELD = '_practice_id';

export const NO_ACTIVE_ASSESSMENT = 'No Active Assessment';

export const MAIN_SCROLLABLE_AREA_PADDING = 25;

export const DATE_FORMAT_MILESTONE = 'MMMM Do, YYYY';

export const ASSESSMENT_STATUS = [
  'Assigned',
  'In progress',
  'Awaiting approval',
  'Accepted',
];

export const EVIDENCE_REVIEW = 'Evidence Review';
export const SELF_ASSESSMENT = 'Self-Assessment';
export const COMPLETE = 'Complete';

const stateLabel = (mode: string, status: string) =>
  mode + (status ? ` - ${status}` : '');

export const ALL_ASSESSMENT_STATES = [
  ...ASSESSMENT_STATUS.map((stat) => stateLabel(SELF_ASSESSMENT, stat)),
  ...ASSESSMENT_STATUS.map((stat) => stateLabel(EVIDENCE_REVIEW, stat)),
  COMPLETE,
];

export enum DashboardType {
  SCORE_ONLY = 'SCORE_ONLY',
  AGGREGATE = 'AGGREGATE',
  C2M2_FULL = 'C2M2_FULL',
  C2M2_QL = 'C2M2_QL',
  C2M2V2_FULL = 'C2M2V2_FULL',
  C2M2V2V1_FULL = 'C2M2V2V1_FULL',
  CIS18_FULL = 'CIS18_FULL',
  CISPLUS_FULL = 'CISPLUS_FULL',
  CMMC_FULL = 'CMMC_FULL',
  CMMCV2_FULL = 'CMMCV2_FULL',
  CMMICSF_FULL = 'CMMICSF_FULL',
  CRI_FULL = 'CRI_FULL',
  CSF_FULL = 'CSF_FULL',
  CSF_QL = 'CSF_QL',
  CSF_GP = 'CSF_GUIDEPOINT',
  CSSA_FULL = 'CSSA_FULL',
  EHRCSF_FULL = 'EHRCSF_FULL',
  ENTERGYCSF_FULL = 'ENTERGYCSF_FULL',
  ESEMSTANDARDS = 'ESEMSTANDARDS_FULL',
  FFIEC1 = 'FFIEC1',
  FHBCSF_FULL = 'FHBCSF_FULL',
  ALLIANT = 'ALLIANT',
  ISO27001 = 'ISO27001',
  RC3_FULL = 'RC3_FULL',
  RPA_FULL = 'RPA_FULL',
  EHR_VIEWER = 'EHR_VIEWER',
  NISTPP_FULL = 'NISTPP_FULL',
}

export interface Phase {
  id: typeof SELF_ASSESSMENT | typeof EVIDENCE_REVIEW;
  title: typeof SELF_ASSESSMENT | typeof EVIDENCE_REVIEW;
  expandedField: 'isSelfAssessmentExpanded' | 'isEvidenceReviewExpanded';
  steps?: any[];
}

const phases: Phase[] = [
  {
    title: SELF_ASSESSMENT,
    id: SELF_ASSESSMENT,
    expandedField: 'isSelfAssessmentExpanded',
  },
  {
    title: EVIDENCE_REVIEW,
    id: EVIDENCE_REVIEW,
    expandedField: 'isEvidenceReviewExpanded',
  },
];

export interface ILifecycleStep {
  phaseIdx: number;
  steps: Array<{
    title: string;
    id: string;
    nested: boolean;
    statusIdx: number;
    phaseIdx: number;
    last: boolean;
  }>;
  id: 'Self-Assessment' | 'Evidence Review';
  title: 'Self-Assessment' | 'Evidence Review';
  expandedField: 'isSelfAssessmentExpanded' | 'isEvidenceReviewExpanded';
}

export const lifecycleSteps = phases.map((phase, phaseIdx) => ({
  ...phase,
  phaseIdx,
  steps: ASSESSMENT_STATUS.map((status, statusIdx) => {
    const last = statusIdx + 1 === ASSESSMENT_STATUS.length;
    return {
      title: status,
      id: `${phase.id} - ${status}`,
      nested: true,
      statusIdx,
      phaseIdx,
      last,
    };
  }),
}));

const selfAssessmentSteps = ASSESSMENT_STATUS.map(
  (status) => `${SELF_ASSESSMENT} - ${status}`
);
const evidenceReviewSteps = ASSESSMENT_STATUS.map(
  (status) => `${EVIDENCE_REVIEW} - ${status}`
);
export const historySteps = selfAssessmentSteps.concat(
  evidenceReviewSteps,
  COMPLETE
);

//exportable svg widget classNames
export const IMPLEMENTED_SUBCATEGORIES_BY_CATEGORY =
  'Implemented_Subcategories_by_Category';
export const CIS18_CONTROL_CHART = 'Cis18Control_Chart';
export const BENCHMARK_COMPARISON = 'Benchmark_Comparison';
export const ASSESSMENT_CHANGE_OVER_TIME = 'Assessment_Change_Over_Time';
export const CURRENT_SCORE_PIE = 'Current_Score_Pie';
export const TARGET_SCORE_PIE = 'Target_Score_Pie';
export const TARGET = 'Target';
export const CURRENT = 'Current';
export const SCORE_GAUGE = 'Score_Gauge';
export const PRACTICES_IMPLEMENTED_BY_DOMAIN =
  'Practices_Implemented_by_Domain';
export const MIL_COMPLETION_BY_DOMAIN = 'MIL_Completion_by_Domain';
export const APPROACH_MANAGEMENT_SCORE_CIRCLE =
  'Approach_Management_Score_Circle';
export const PROGRESS_HISTORY = 'Progress_History';
export const NIST_CHART = 'NIST_Chart';
export const ASSESSMENT_COMPARISON = 'Assessment_Comparison';

export enum NavigationMode {
  DOMAIN = 'Domain',
  OBJECTIVE = 'Objective',
  PRACTICE = 'Practice',
  FLAGGED = 'Flagged',
  UNANSWERED = 'Unanswered',
}

export enum NavigationDirection {
  NEXT = 'Next',
  PREVIOUS = 'Previous',
}

export const EEI_CONCLUSION_DOMAIN_ID = '.FIN';
export const EEI_CONCLUSION_PRACTICE_ID = '.FIN.1';
export const EEI_CONCLUSION_PRACTICE_FQN = 'FIN.1';
