// import { getIdToken } from 'common/utils/userContext';
import { initialState as insuranceInitialState } from 'insurance/apolloInitialState';
import { initialState as quantInitialState } from 'quantification/apolloInitialState';

// This splat is used by the local type generator and so must contain all initial state
// objects.  Be aware if you go messing with it.
// When building these files, if you find yourself needing Float to the be the type and
// needing 0.0 to be the initialized value, use -0.0 (or just -0).  That's the indicator
// to the type generation library that you need a Float.
export const initialLocalState = {
  __typename: 'Query' as const,
  ...insuranceInitialState,
  ...quantInitialState,
};

export const resolvers = {
  Query: {
    isInsuranceReviewer: async () => {
      //const idToken = await getIdToken();
      //const groups = getCognitoGroupsFromToken(idToken);
      //return userIsInsuranceReviewer(groups);
      return false;
    },
  },
};
