import React from 'react';
import styled from 'styled-components/macro';
import { ProgressBar } from '@blueprintjs/core';

import logo from './logo.svg';

const SplashWrapper = styled.div`
  max-width: 609px;
  margin: 272px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    margin-bottom: 80px;
  }

  .bp3-progress-bar {
    width: 100%;
    margin-bottom: 34px;
  }
`;

const SubHeading = styled.p`
  color: #505f79;
  font-size: 24px;
  line-height: 32px;
  word-wrap: break-word;
  margin-bottom: 16px;
`;

const Copy = styled.p`
  color: #505f79;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
`;

export const AxioSplash = () => {
  return (
    <SplashWrapper>
      <img src={logo} alt={'Axio'} />
      <ProgressBar intent="primary" />
      <SubHeading>Loading...</SubHeading>
      <Copy>We're preparing your page, this will only take a moment.</Copy>
    </SplashWrapper>
  );
};
