export const initialState = {
  tagIdForGrouping: '',
  showReportLoading: false,
  showAddCollection: false,
  showConcurrentImpactDialog: false,
  showNewControl: false,
  showEditPracticeGroup: false,
  showAddNewPracticeGroup: false,
  showAddNewScenario: false,
  showEditScenario: false,
  showEditCollectionSidebar: false,
  showSharing: false,
  showUser: false,
  showClone: false,
  showAddNewImpact: false,
  showCustomerFeedback: false,
  showQuantBoardReport: null,
  showQuantUpsell: false,
  defaultQuadrantId: '',
  lastNewImpactId: '',
  selectedControlInitiativeIds: [],
  sortOrder: ['D_Impact', 'A_DateUpdated'],
  lastFilter: '',
};
