import {
    useGetActiveModelLazyQuery,
  useGetLicensedModelsLazyQuery,
} from 'common/graphql/graphql-hooks';
import { useCallback } from 'react';

export type UseInitialModelsOutput = {
  fetchModels: () => void;
};

export const useIntitialModels = () => {
  const [getLicensedModels] = useGetLicensedModelsLazyQuery();
  const [getActiveModel] = useGetActiveModelLazyQuery()

  const fetchModels = useCallback(() => {
    getActiveModel();
    getLicensedModels();
  }, [getActiveModel, getLicensedModels]);

  return {
    fetchModels,
  };
};
