/**
 * Handles logging the user out due to application idle.  We tried a library for this but
 * generating actions for every single mouse move turns out to be very bad.  Instead,
 * we expect that user activity that is meaningful will generate actions against
 * the store and we can use those to trigger timeout or not.
 */
import { AnyAction } from 'redux';
import { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { shelobServerPath } from 'common/utils/AxioUtilities';
import { Auth } from '@axio_cs/auth';
import { IRootState } from '../../reducers';
import { isAboutToLogout, isSubscribed } from '../selectors/app';
import { saveNow, unsubscribe } from './persistence';
import { userGoneIdle, userIsBack } from '.';
import Logger from 'common/utils/Logger';

type Dispatch = ThunkDispatch<IRootState, void, AnyAction>;

const ignoreAsActivityMap = {
  NETWORK_TIMEOUT: true,
  NETWORK_DISCONNECT: true,
  NETWORK_CONNECT: true,
  'socket.io/error': true,
  REQUEUE_UPDATE: true,
  COGNITO_LOGIN: true,
  IDLE_USER_INACTIVE: true,
  NETWORK_GENERIC_RETRY: true,
  'persistence/flush_network_saves': true,
};

// These events are sent from the server.  We don't want actions that
// the server is sending to be considered activity by the client.
const ignoreAsActivityWildcards = [
  'item_deleted/',
  'item_replaced/',
  'item_inserted/',
  'item_updated/',
  '@@router/',
];

const startActivityTimeoutMap = {
  COGNITO_LOGIN: true,
  IDLE_USER_IS_BACK: true,
};

const killActivityTimeoutMap = {
  COGNITO_LOGOUT: true,
};

const ONE_MINUTE = 60 * 1000;
const FIVE_MINUTES = 5 * ONE_MINUTE;
const FIFTEEN_MINUTES = 15 * ONE_MINUTE;
const MAX_IDLE_TIME = FIFTEEN_MINUTES; // The max amount of idle time we'll allow
const MAX_WARNING_TIME = FIVE_MINUTES; // The max time we'll leave the warning up

let timeoutId: number | undefined;

function updateLastActivityTime(action: AnyAction) {
  if (
    !action.type ||
    action.type in ignoreAsActivityMap ||
    ignoreAsActivityWildcards.some((i) => action.type.startsWith(i))
  ) {
    return;
  }

  localStorage.setItem('lastActivity', Date.now().toString());
}

function calcRemainingLife(alreadyWarned: boolean, lastAct: number) {
  // If we've already warned the user, wait the warning time.
  if (alreadyWarned) {
    return MAX_WARNING_TIME;
  }

  // If lastActivity is not set, return max we're supposed to wait.
  if (!lastAct) {
    return MAX_IDLE_TIME;
  }

  // Otherwise, return the difference between our max time and
  // the amount of time we've been idle.  UNLESS that is somehow
  // less than zero - because we've been idle for so long that
  // we've outstripped max time and in that case, go ahead and
  // return 0.
  return Math.max(0, MAX_IDLE_TIME - (Date.now() - lastAct));
}

/**
 * Check the timeout value to determine if we should restart the timer
 */
function processTimeout(getState: () => IRootState, dispatch: Dispatch) {
  timeoutId = undefined;

  const alreadyWarned = isAboutToLogout(getState());
  // If you've been warned, we can just log you out.
  if (alreadyWarned) {
    saveAndLogout(getState, dispatch);
    return;
  }

  const lastActivity = Number(
    localStorage.getItem('lastActivity') || Date.now()
  );

  // If the idle time is greater than the max allowable, dispatch the warning message
  const goneIdle = Date.now() - lastActivity >= MAX_IDLE_TIME;

  if (goneIdle) {
    dispatch(userGoneIdle());
  }

  // Reset and try again.
  return setIdleTimeout(getState, dispatch, goneIdle);
}

function saveAndLogout(
  getState: () => IRootState,
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
) {
  dispatch(saveNow());

  if (isSubscribed(getState())) {
    dispatch(unsubscribe());
  }

  const axioAuth = new Auth(shelobServerPath());

  axioAuth.signOut();

  const origin = window.location.origin;
  const url = new URL('/auth/login', origin);
  Logger.info('Logging user out due to inactivity');
  window.location.assign(`${url}`);
}

/**
 * Called recursively.  If the user isn't logged in, the timer will
 * just die.  Otherwise, it looks for inactivity and dispatches actions
 * if the user is idle.
 */
function setIdleTimeout(
  getState: () => IRootState,
  dispatch: Dispatch,
  argAlreadyWarned: boolean | null = null
) {
  const alreadyWarned =
    argAlreadyWarned === null ? isAboutToLogout(getState()) : argAlreadyWarned;

  const lastActivity = Number(
    localStorage.getItem('lastActivity') || Date.now()
  );

  const remainingLife = calcRemainingLife(alreadyWarned, lastActivity);

  timeoutId = window.setTimeout(
    () => processTimeout(getState, dispatch),
    remainingLife
  );
}

function setIdleTimeoutIfNecessary(
  getState: () => IRootState,
  dispatch: Dispatch,
  action: AnyAction
) {
  if (!timeoutId && action.type in startActivityTimeoutMap) {
    localStorage.setItem('lastActivity', Date.now().toString());
    setIdleTimeout(getState, dispatch);
    return true;
  }

  return false;
}

function killIdleTimeoutIfNecessary(action: AnyAction) {
  if (timeoutId && action.type in killActivityTimeoutMap) {
    window.clearTimeout(timeoutId);
    timeoutId = undefined;
    return true;
  }

  return false;
}

export const idleManager = (): ThunkMiddleware<IRootState> => (store) => {
  const receiveMessage = (ev: StorageEvent) => {
    if (ev.key === 'loginStatus' && ev.newValue === 'userIsBack') {
      store.dispatch(userIsBack(false));
    }

    if (ev.key === 'loginStatus' && ev.newValue === 'logout') {
      saveAndLogout(store.getState, store.dispatch);
    }
  };

  window.addEventListener('storage', receiveMessage);

  return (next) => (action: AnyAction) => {
    const getState = store.getState;
    const dispatch = store.dispatch;

    updateLastActivityTime(action);

    if (!setIdleTimeoutIfNecessary(getState, dispatch, action)) {
      if (!killIdleTimeoutIfNecessary(action)) {
        const alreadyWarned = isAboutToLogout(getState());

        if (
          alreadyWarned &&
          action &&
          action.type &&
          action.type !== 'IDLE_USER_IS_BACK' &&
          !(action.type in ignoreAsActivityMap)
        ) {
          // We're back because of an event other than "user is back", so dispatch that event.
          dispatch(userIsBack());
        }
      }
    }
    next(action);
  };
};
