import React, { createContext, useCallback, useRef, useEffect } from 'react';
import Logger from './Logger';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { shelobServerPath } from 'common/utils/AxioUtilities';
import { Auth } from '@axio_cs/auth';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from 'reducers';
import { fireHubSpotEvent, graphqlIdpPath } from './AxioUtilities';
import {
  CurrentUserQuery,
  useCurrentUserLazyQuery,
} from 'common/graphql/graphql-hooks';
import { useIntitialModels } from 'common/hooks/useInitialModels';

const REFRESH_DELAY = 60000;

type Dispatch = ThunkDispatch<IRootState, void, AnyAction>;

interface IUserContext {
  currentUser?: CurrentUserQuery['me'];
  client: Auth;
  logout: (apolloClient: ReturnType<typeof useApolloClient>) => Promise<void>;
}

const initialState = {
  currentUser: undefined,
  client: new Auth(''),
  logout: () => Promise.resolve(),
};

export const UserContext = createContext<IUserContext>(initialState);

interface UserProviderProps {
  client: Auth;
  children: React.ReactNode;
}

const userNotFoundException = 'UserNotFoundException';
const userNotFoundError = { code: userNotFoundException };

export const UserProvider: React.FunctionComponent<UserProviderProps> = ({
  children,
  client,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const apolloClient = useApolloClient();
  const intervalId = useRef<number>();
  const { fetchModels } = useIntitialModels();
  const [fetchCurrentUser, { data }] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onError: async () => {
      await logout(apolloClient);

      throw userNotFoundError;
    },
    onCompleted: (result) => {
      try {
        // Only inform HubSpot if the user is a free tool user
        if (result.me && !result.me.employer) {
          fireHubSpotEvent(result.me.email, 'User logged in');
        }
      } catch {
        Logger.error('Failed send login to HubSpot');
      }
    },
  });

  const logout = useCallback(
    async (_apolloClient: ReturnType<typeof useApolloClient>) => {
      await client.signOut();

      window.clearInterval(intervalId.current);

      _apolloClient
        .resetStore()
        .catch((err: Error) =>
          Logger.error(
            `Failure to reset store on logout with this error: ${err.message}`
          )
        );

      localStorage.setItem('loginStatus', 'logout');

      dispatch({ type: 'COGNITO_LOGOUT' });
    },
    [client, dispatch]
  );

  const setRefreshInterval = useCallback(() => {
    if (intervalId.current) {
      window.clearInterval(intervalId.current);
    }

    intervalId.current = window.setInterval(() => {
      client.getSession().then((session) => {
        if (session.isErr()) {
          logout(apolloClient);
        }
      });
    }, REFRESH_DELAY);
  }, [apolloClient, client, logout]);

  useEffect(() => {
    client.getSession().then((session) => {
      if (session.isOk()) {
        localStorage.removeItem('loginStatus');

        dispatch({ type: 'COGNITO_LOGIN' });

        setRefreshInterval();
        fetchCurrentUser();
        fetchModels();
      } else {
        // Local Dev Headless Auth
        if (process.env.REACT_APP_USERNAME && process.env.REACT_APP_PASSWORD) {
          client
            .signIn(
              process.env.REACT_APP_USERNAME,
              process.env.REACT_APP_PASSWORD
            )
            .then((result) => {
              if (result.isOk()) {
                window.location.reload();
              }
            });
        } else {
          const origin = window.location.origin;
          const url = new URL('/auth/login', origin);
          Logger.info('Error with user session, logging out', session.error);
          window.location.assign(`${url}`);
        }
      }
    });
  }, [client, dispatch, fetchCurrentUser, fetchModels, setRefreshInterval]);

  const values = React.useMemo(
    () => ({
      currentUser: data?.me,
      client,
      logout,
    }),
    [data?.me, client, logout]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      '`useUser` hook must be used within a `UserProvider` component'
    );
  }

  return context;
};

/*
 * Returns the current token for the user or null if the user isn't logged in.
 */
export const getIdToken = async (): Promise<string | null> => {
  const axioAuth = new Auth(shelobServerPath());
  const axioSession = await axioAuth.getSession();

  if (axioSession.isOk() && axioSession.value) {
    return axioSession.value.getToken().getCognitoIdToken() as string;
  }

  return null;
};

export const getSsoStatus = async (email: string) => {
  try {
    const url = graphqlIdpPath();
    const data = { email: email };
    const ssoResp = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Location, X-Request-URL',
      },
      method: 'POST',
      body: JSON.stringify({
        data,
      }),
    });
    const { data: hasSso } = await ssoResp.json();
    return !!hasSso;
  } catch (err: any) {
    return false;
  }
};
