import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  Operation,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import apolloLogger from 'apollo-link-logger';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import ObjectID from 'bson-objectid';
import { OperationDefinitionNode } from 'graphql';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { Store } from 'redux';

import {
  InitialStateDocument,
  InitialStateQuery,
} from 'common/graphql/graphql-hooks';
import { errorLink } from 'common/utils/ApolloErrorLink';
import { ReduxLink } from 'common/utils/ApolloReduxLink';
import { graphqlServerPath } from 'common/utils/AxioUtilities';
import Logger from 'common/utils/Logger';
import { getIdToken } from 'common/utils/userContext';
import { cache } from './setupApolloCache';
import { initialLocalState, resolvers } from './setupApolloInitialState';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

// Set of slow queries that are unbatched as to not hold up page loads.
// Browsers have a maximum of ~6 concurrent connections per host so YMMV.
const UNBATCHED_QUERIES = new Map([
  ['LossExceedanceCurve', 1],
  ['NodeStickWidget', 1],
  ['ScenarioImpactTotals', 1],
  ['SidebarScenarioList', 1],
  ['CurrentUser', 1],
  ['GetActiveModel', 1],
  ['GetLicensedModels', 1],
]);

const clientId = new ObjectID().toHexString();

const convertProtocol = (path: string) => {
  // This code is here so we don't have to use the new URL() parser
  // which isn't supported on IE 11.  Since we load up immediately,
  // we can't even show the "Don't use ME!" screen if this function
  // doesn't work.
  if (!path.includes('://')) {
    return 'ws://' + path;
  }
  return path.replace(/^(?:http)?(s?):\/\//i, 'ws$1://');
};

const shouldRetry = (error: any, operation: Operation) => {
  if (error && operation) {
    // We don't want the client to continue trying to log us in when
    // the server is responding with an error to the request.
    return (
      (error.name ?? '') !== 'ServerError' ||
      operation.operationName !== 'CurrentUser'
    );
  } else {
    return false;
  }
};

const retryLink = new RetryLink({
  attempts: {
    max: Infinity,
    retryIf: (error, operation) => {
      return shouldRetry(error, operation);
    },
  },
});

const headersLink = setContext(async (_, { headers }) => {
  const newToken = await getIdToken();
  if (!newToken) {
    Logger.error("HeadersLink couldn't fetch a token.");
  }
  return {
    headers: {
      ...headers,
      authorization: newToken,
      clientId,
    },
  };
});

const terminatingLinkOptions = {
  uri: graphqlServerPath,
  credentials: 'same-origin',
};
const maybeBatchHttpLink = split(
  ({ query }) => {
    const { kind, operation, name } = getMainDefinition(
      query
    ) as OperationDefinitionNode;
    return (
      kind === 'OperationDefinition' &&
      operation === 'query' &&
      UNBATCHED_QUERIES.has(name?.value ?? '')
    );
  },
  new HttpLink(terminatingLinkOptions),
  new BatchHttpLink(terminatingLinkOptions)
);
const httpLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(
      query
    ) as OperationDefinitionNode;
    return kind === 'OperationDefinition' && operation === 'mutation';
  },
  createUploadLink(terminatingLinkOptions),
  maybeBatchHttpLink
);

const wsLink = new WebSocketLink({
  uri: convertProtocol(graphqlServerPath()),
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => ({
      authToken: await getIdToken(),
      clientId,
    }),
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const httpOrWslink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(
      query
    ) as OperationDefinitionNode;
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const persistedQueryLink = createPersistedQueryLink({ sha256 });

export const {
  link: networkLink,
  useApolloNetworkStatus,
} = createNetworkStatusNotifier();

export const apolloClient = (store: Store) => {
  const client = new ApolloClient({
    link: ApolloLink.from([
      apolloLogger,
      new ReduxLink(store),
      networkLink,
      retryLink,
      errorLink,
      headersLink,
      persistedQueryLink,
      httpOrWslink,
    ]),
    cache,
    resolvers,
    defaultOptions: {
      mutate: { errorPolicy: 'all' },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  });
  client.onResetStore(() =>
    Promise.resolve(
      cache.writeQuery<InitialStateQuery>({
        query: InitialStateDocument,
        data: initialLocalState,
      })
    )
  );
  return client;
};

cache.writeQuery<InitialStateQuery>({
  query: InitialStateDocument,
  data: initialLocalState,
});

let singletonClient: ApolloClient<any> | null = null;
export const setSingletonClient = (argClient: ApolloClient<any>) => {
  singletonClient = argClient;
};
export const getSingletonClient = () => singletonClient;
