import React, { useMemo } from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import Admin from 'admin';
import Assessments from 'assessments';
import Insurance from 'insurance';
import Quantification from 'quantification';
import QuickQuant from 'quickquant';
import { AxioSplash } from 'common/components/AxioSplash';
import { useUser } from 'common/utils/userContext';

export const MainRouter = () => {
  const { currentUser } = useUser();

  const userLoaded = useMemo(() => currentUser, [currentUser]);

  if (!currentUser) {
    return <AxioSplash />;
  }

  const PrivateRoute = ({
    children,
    ...rest
  }: Omit<RouteProps, 'children'> & { children: React.ReactNode }) => {
    return (
      <Route
        {...rest}
        render={() => (userLoaded ? children : <AxioSplash />)}
      />
    );
  };
  return (
    <Switch>
      <PrivateRoute path="/assessments">
        <Assessments />
      </PrivateRoute>
      <PrivateRoute path="/insurance/:component?/:reviewId?/:policyId?/:fileId?">
        <Insurance />
      </PrivateRoute>
      <Redirect from="/quant/:id" to="/quantification/:id" />
      <Redirect from="/quant" to="/quantification" />
      <PrivateRoute path="/quantification/:component?/:collectionId?/:scenarioId?">
        <Quantification />
      </PrivateRoute>
      <PrivateRoute path="/admin/:component?/:id?">
        <Admin />
      </PrivateRoute>
      <PrivateRoute path="/quickquant">
        <QuickQuant />
      </PrivateRoute>
      {['/index.html', '/'].map((path) => (
        <Redirect exact from={path} to="/assessments" key={path} />
      ))}
      <Route path="*">
        <div>
          <h1>Oops!</h1>
          <p>You're looking for a page that doesn't exist.</p>
        </div>
      </Route>
    </Switch>
  );
};
